// jQuery fallback //

if (typeof jQuery == 'undefined') {
    document.write(unescape("%3Cscript src='/javascript/jquery-1.11.1.min.js' type='text/javascript'%3E%3C/script%3E"));
}

// Mobile menu //

jQuery("document").ready(function($){
    
    var nav = $('header#page-header .mobile-menu');
    var footer_nav = $('footer .footer-links ul li.menu-item-3');
    var header = $('header#page-header');
    var sitename = $('header#page-header .mobile-menu a.site-name');
    var menu_button = $('header#page-header .mobile-menu a.menu-button');
    var top_button = $('header#page-header .mobile-menu a.top-button');
    $(window).scroll(function () {
        if ($(this).scrollTop() > 130) {
            nav.addClass("f-nav");
            header.addClass("f-nav");
            sitename.fadeIn(800).css("display","inline-block");
        } else {
            nav.removeClass("f-nav");
            header.removeClass("f-nav");
            sitename.fadeOut(800);
        }
        
        var docViewBottom = $(this).scrollTop() + $(window).height();

        var elemTop = $(footer_nav).offset().top;
        var elemBottom = elemTop + $(footer_nav).height();
        
        if (elemTop <= docViewBottom) {
            //nav.removeClass("f-nav");
            //header.removeClass("f-nav");
            menu_button.hide();
            top_button.show().css("display","block");
        } else {
            menu_button.show();
            top_button.hide();
        }
        
    });
 
});


// SVG fallback //

if(!Modernizr.svg) {
    $('img[src*="svg"]').attr('src', function() {
        return $(this).attr('src').replace('.svg', '.png');
    });
}


// Accordion //

$(function() {
    $( ".accordion" ).accordion({
        heightStyle: "content",
        collapsible: true,
        active: 999999999
    });
});
